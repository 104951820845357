/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto|Rubik|Titillium+Web');
@import '@angular/material/prebuilt-themes/indigo-pink.css';
html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: 'Roboto', sans-serif;
    font-family: 'Titillium Web', sans-serif;
    font-family: 'Rubik', sans-serif;
    background-color: #fafafa;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons.md-16 { font-size: 16px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.transparent { color: rgba(253, 253, 253, 0.452); }
.material-icons.white { color: rgb(253, 253, 253); }
.material-icons.gold { color: #F6AB3F; }
.material-icons.pink { color: #f67cd6; }
.material-icons.green { color: #3cd38f; }
.material-icons.black { color: rgb(43, 40, 36); }

.cdk-overlay-pane {
    @media only screen and (max-width: 731px) {
        width: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
    }
}